import React from 'react';
// import DirTree from './Components/DirTree'
// import MainContainer from './Components/MainContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

import './App.css';

function App() {
  return (
    <div className="App">
      <h2>
        <span role="img" aria-label="sign">🚧 </span>
        This site is under construction
        <span role="img" aria-label="coder">👨‍💻</span>
      </h2>
      <div className="icons">
        <a href="https://www.linkedin.com/in/carlos-estrada/">
          <FontAwesomeIcon className="icon" icon={faLinkedin} size="5x"/>
        </a>
        <a href="https://github.com/Kachyz">
          <FontAwesomeIcon className="icon" icon={faGithub} size="5x"/>
        </a>
      </div>
    </div>
  );
}

export default App;
